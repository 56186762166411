<template>
  <div @click="route()" class="service-link">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },

    props: {
      service: {
        type: Object,
      },
    },

    methods: {
      route() {
        if (
          this.service.channels.length == 1 &&
          this.service.channels[0].name == "WalkUp"
        ) {
          this.$router.push("/service/" + this.service.id);
        }

        const autoRedirect = this.service.options.autoRedirect ?? "";
        if (autoRedirect != "") {
          window.location.href = autoRedirect;
        } else {
          this.$router.push("/service/" + this.service.id);
        }
      },
    },
  };
</script>

<style lang="scss"></style>
